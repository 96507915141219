import styled, { css } from 'styled-components';
import { Background2, HPHGrey, HPHHorizonBlue, HPHOrangeLight, HPHRed, MontserratFont } from 'veronica-ui-component/dist/component/core/Colors';
import { CardProps } from '../NotificationDialog/NotificationCard';

const getWidth = (Props: CardProps) => Props.width && css`width: ${Props.width};`;
const getHeight = (Props: CardProps) => Props.height && css`height: ${Props.height};`;

export const StyledDiv = styled.div<CardProps>` 
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  margin: 1rem;
  cursor: pointer;
  display: flex;
  background: ${Background2};
  border-radius: 8px;
  box-shadow: -15px 12px 40px 0px rgba(112, 144, 176, 0.2);
  flex-direction: row;
  padding-left: 15px;
  padding-bottom: 20px;
  ${getWidth}
  ${getHeight}
`;

export const StyledIcon = styled.div`
  width: 36px;
  height: 36px;
  background: ${HPHRed};
  border: 4px solid ${HPHOrangeLight};
  border-radius: 50%;
  margin-top: 15px; 
  margin-right: 10px;
`;

export const StyledAlert = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    pointer-events: none;
    width: 25px;
    height: 25px;
  }
  margin: 3px;
`;

export const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 20px;
`;

export const StyledLabel = styled.div`
  font-size: 1rem;
  font-weight: 700;
  font-family: ${MontserratFont};
`;

export const StyledCross = styled.div`
  position: absolute;
  right: 0;
  cursor: pointer;
  width: 30px;
  height: 30px;
  padding: 5px;
  margin-right: 2px;
  margin-top: 2px;
  display: flex;
  align-content: center;
  justify-content: center;

  svg {
    pointer-events: none;
    width: 16px;
    height: 16px;
    &:hover {
      background: ${HPHHorizonBlue};
      border-radius: 50%;
      width: 16px;
      height: 16px;
    }
  }
`;

export const StyledButton = styled.div`
  position: relative;
  display: flex;
  margin-top: 15px;
  margin-bottom: 15px; /* 添加底部空白 */
`;

export const StyledButton1 = styled.div`
  margin-right: 10px;
`;

export const StyledText = styled.div`
  display: flex;
  letter-spacing: 0px;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  overflow: hidden;
  font-size: 1rem;
  font-family: ${MontserratFont};
  line-height: 1.4;
  font-weight: 400;
  color: ${HPHGrey};
  top: 20px;
`;