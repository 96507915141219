import { EMPTY_NOTIFICATION_DIALOG_MODEL } from "presentation/model/NotificationDialog/NotificationDialogModel";
import { useState } from "react";
import { createContainer } from "react-tracked";
  
const {
Provider: NotificationDialogProvider,
useTracked: useNotificationDialogTracked
} = createContainer(() => useState(EMPTY_NOTIFICATION_DIALOG_MODEL), {concurrentMode: true});

export { NotificationDialogProvider, useNotificationDialogTracked };

