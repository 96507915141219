/* eslint-disable import/extensions */
import React from 'react';
import { Icons } from 'veronica-ui-component/dist/common/Icon';
import { DynamicProp } from 'veronica-ui-component/dist/common/commonInterface';
import { HPHButton } from 'veronica-ui-component/dist/component/core/Button/Button';
import { HPHWhite } from 'veronica-ui-component/dist/component/core/Colors';
import { SVGIcon } from 'veronica-ui-component/dist/component/core/SVGIcon/SVGIcon';
import { StyledAlert, StyledButton, StyledButton1, StyledCard, StyledCross, StyledDiv, StyledIcon, StyledLabel, StyledText } from '../styled/NotificationCard.styled';

export interface CardProps extends DynamicProp {
    id?: string | 'card',
    width?: number | string,
    height?: number | string,
    heading?: string,
    text?: string,
    secondaryButtonLabel?: string,
    primaryButtonLabel?: string,
    disabledIcon?: boolean | false,
    disabledLabel?: boolean | false,
    disabledButton?: boolean | false,
    onClick?: () => void,
    onConfirm?: () => void,
}

const NotificationCard: React.FC<CardProps> = ({
    id = 'card',
    width = '400px',
    height = '150px',
    heading = 'Alert Issue 1',
    text = 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Enim, nihil rem!',
    disabledIcon = false,
    disabledLabel = false,
    disabledButton = false,
    secondaryButtonLabel = 'Cancel',
    primaryButtonLabel = 'Confirm',
    onClick,
    onConfirm,
    ...rest
}): any => {
    return (
        <>
            <StyledDiv id={id} width={width} height={height} {...rest}>
                {disabledIcon ? '' : <StyledIcon><StyledAlert>{Icons('Icon-alert', HPHWhite)}</StyledAlert></StyledIcon>}
                <StyledCard >
                    {disabledLabel ? '' : <StyledLabel>{heading}</StyledLabel>}
                    <StyledText> {text} </StyledText>
                    {disabledButton ? '' :
                        <StyledButton >
                            {secondaryButtonLabel && <StyledButton1><HPHButton label={secondaryButtonLabel} size={'Small'} theme={'Secondary'} onClick={() => onClick && onClick()} /></StyledButton1>}
                            {primaryButtonLabel && <HPHButton label={primaryButtonLabel} size={'Small'} theme={'Primary'} onClick={() => onConfirm && onConfirm()} />}
                        </StyledButton>}
                </StyledCard>
                {disabledButton ? <StyledCross onClick={() => onClick && onClick()}><SVGIcon fileName="Icon-cross" /></StyledCross> : ''}

            </StyledDiv>
        </>
    );
};

export { NotificationCard };

