import { useANAInfo } from "presentation/hook/ANAInfo/useANAInfo";
import { useNotificationDialogVM } from "presentation/hook/NotificationDialog/useNotificationDialogVM";
import { NotificationDialogCard } from "presentation/model/NotificationDialog/NotificationDialogModel";
import { useNotificationDialogTracked } from "presentation/store/NotificationDialog/NotificationDialogProvider";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { NotificationCard } from "./NotificationCard";

const refreshInterval = Number(process.env.REACT_APP_REFRESH_NOTIFICATION_INTERVAL) ?? 300000;
const NotificationDialogComponent = () => {
    const notificationVM = useNotificationDialogVM();
    const [notificationState] = useNotificationDialogTracked();
    const { userName } = useANAInfo();
    const timerRef = useRef<number | null>(null);
    const [currentShowNotifications, setCurrentShowNotifications] = useState<NotificationDialogCard[]>([]);
    const [currentShowCards, setCurrentShowCards] = useState<JSX.Element[]>([]);

    useEffect(() => {
        if (!window || !userName) return;

        const fetchNotifications = async () => {
            await notificationVM.getNotificationsByUser(userName ?? "");
        };

        timerRef.current = window.setInterval(fetchNotifications, refreshInterval);

        return () => {
            if (timerRef.current !== null) {
                clearInterval(timerRef.current);
            }
        };
    }, [notificationVM, userName]);

    const onConfirm = useCallback((feedback: NotificationDialogCard) => {
        notificationVM.onFeedbackNotification(feedback, "CONFIRM", userName);
    }, [userName, notificationVM]);

    const onClick = useCallback((feedback: NotificationDialogCard) => {
        notificationVM.onFeedbackNotification(feedback, "CANCEL", userName);
    }, [userName, notificationVM]);

    useEffect(() => {
        if (JSON.stringify(notificationState.showNotifications) !== JSON.stringify(currentShowNotifications)) {
            setCurrentShowNotifications(notificationState.showNotifications);
            const cards = notificationState.showNotifications?.map((notification, index) => {
                return <NotificationCard
                    {...notification}
                    onConfirm={() => onConfirm(notification)}
                    onClick={() => onClick(notification)}
                />;
            });
            setCurrentShowCards(cards);
        }
    }, [currentShowNotifications, notificationState.showNotifications, onClick, onConfirm]);

    return (
        <>
            {currentShowCards}
        </>
    );
}

export default memo(NotificationDialogComponent);