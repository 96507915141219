import { NotificationDialogRepoImpl } from "domain/repository/NotificationDialog/NotificationDialogRepoImpl";
import { useNotificationDialogTracked } from "presentation/store/NotificationDialog/NotificationDialogProvider";
import { NotificationDialogVM } from "presentation/viewModel/NotificationDialog/NotificationDialogVM";
import { useMemo } from "react";

export const useNotificationDialogVM = () => {
    const [, setNotificationDialogState] = useNotificationDialogTracked();
    const notificationDialogVM = useMemo(() =>
        NotificationDialogVM({
            dispatch: [setNotificationDialogState],
            notificationDialogRepo: NotificationDialogRepoImpl()
            
        }), [setNotificationDialogState])

    return notificationDialogVM
}