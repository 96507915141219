import { NotificationDetailEntity } from "domain/entity/NotificationDialog/NotificationDetailEntity";
import { NotificationHeaderEntity } from "domain/entity/NotificationDialog/NotificationHeaderEntity";
import authAxiosInstance from "../axios/authAxiosInstanc";
import { axiosGetData, axiosPostData } from "../axios/AxiosBasicImpl";
import { NotificationDialogRepository } from "./NotificationDialogRepo";

export const NotificationDialogRepoImpl = (): NotificationDialogRepository => {
    const url = "/v1/notification";
    const urlDetail = "/v1/notificationDetail";
    const getAllNotifications = async (): Promise<NotificationHeaderEntity[]> => {
        return await axiosGetData(authAxiosInstance, url, []).then(res => {
            return res.data
        }).catch(err => {
            return [];
        });
    }

    const getNotificationByUser = async (userName: string): Promise<NotificationHeaderEntity[]> => {
        return await axiosGetData(authAxiosInstance, `${url}/findNotificationsByUser/${userName}`, []).then(res => {
            return res.data
        }).catch(err => {
            return [];
        });
    }

    const feedbackNotification = async (notificationDetail: NotificationDetailEntity): Promise<String> => {
        return await axiosPostData(authAxiosInstance, urlDetail, notificationDetail).then(res => {
            if (res.msg === "OK") {
                return res.data;
            } else {
                let errorMessage = "feedback notification Failure."
                if (res.data && typeof (res.data) === "string") {
                    errorMessage = res.data;
                }
                return "Error:" + errorMessage;
            }
        }).catch(err => {
            return "Error:" + err.Message;
        });
    }

    return {
        getAllNotifications: getAllNotifications,
        getNotificationByUser: getNotificationByUser,
        feedbackNotification: feedbackNotification,
    }
};