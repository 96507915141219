type EnVoiceOption =
    'Microsoft David - English (United States)'
    | 'Microsoft Mark - English (United States)'
    | 'Microsoft Zira - English (United States)'
    | 'Google US English'
    | 'Google UK English Female'
    | 'Google UK English Male'

export const textToSpeech = (text: string, selectedVoiceName: EnVoiceOption = 'Microsoft Zira - English (United States)') => {
    const synth = speechSynthesis;
    const voices = synth.getVoices()
    // Check if speaking
    if (synth.speaking) {
        return;
    }
    // Get speak text
    const speakText = new SpeechSynthesisUtterance(text);

    // Speak end
    speakText.onend = e => {

    };

    // Speak error
    speakText.onerror = e => {

    };

    // Set pitch and rate
    speakText.rate = 1;
    speakText.pitch = 1;
    const selectedVoice = voices.find((voice) => voice.voiceURI === selectedVoiceName) ?? null
    speakText.voice = selectedVoice
    // Speak
    synth.speak(speakText);
};
