import { useWorkspaceTracked } from "presentation/store/NavigationMenu/NavigationMenu";
import { useCallback, useEffect, useState } from "react";

interface IVersionInformation { }

export const VersionInformation: React.FC<IVersionInformation> = () => {
  const [versionPos, setVersionPos] = useState<any>({ left: '85%', top: '90%' });
  const [initVersionPos, setInitVersionPos] = useState<any>({ left: '85%', top: '90%' });
  const [workspaceState, setWorkspaceState] = useWorkspaceTracked();
  const { showModalForVersionIcon, showModalForVersionInformation } = workspaceState;

  const handleClick = useCallback(() => {
    setWorkspaceState(prevState => ({ ...prevState, showModalForVersionIcon: false, showModalForVersionInformation: true }));
  }, [setWorkspaceState]);

  const handleDragStart = useCallback((e: any) => {
    setInitVersionPos({ left: (e.pageX - (42 / 2)) + 'px', top: (e.pageY - (18)) + 'px' });
  }, []);

  const handleDrag = useCallback((e: any) => {
    e.preventDefault();
    setVersionPos({ left: (e.pageX - (42 / 2)) + 'px', top: (e.pageY - (18)) + 'px' });
  }, []);

  const handleDragEnd = useCallback((e: any) => {
    const divArea: any = document.querySelector('body div.work-space div.wrapper');
    const div: any = document.querySelector('#verson-control-fixed-icon');
    if (!div || !divArea) return;

    const { left: leftArea, top: topArea, right: rightArea, bottom: bottomArea } = divArea.getBoundingClientRect();
    const { left, top } = { left: (e.pageX - (42 / 2)), top: (e.pageY - (18/2)) };

    // eslint-disable-next-line no-mixed-operators
    if (left < (leftArea + 42) || left > (rightArea - 70) || top < (topArea + 30) || top > (bottomArea - 20)) {
      setVersionPos(initVersionPos);
    } else {
      setVersionPos({ left: (e.pageX - (42 / 2)) + 'px', top: (e.pageY - (18)) + 'px' });
    }
  }, [initVersionPos]);

  useEffect(() => {
    if (!showModalForVersionInformation) {
      setVersionPos({ left: '85%', top: '90%' });
    }
  }, [showModalForVersionInformation]);

  if (!showModalForVersionIcon) return null;
  return <div className="verson-control-fixed-icon" id="verson-control-fixed-icon" draggable="true" onDragStart={handleDragStart} onDrag={handleDrag} onDragEnd={handleDragEnd} onClick={handleClick} style={{ ...versionPos }}>HPUK</div>
}