import { CardProps } from "veronica-ui-component/dist/component/core/NotificationCard/NotificationCard";

export interface NotificationDialogCard extends CardProps {
    notificationId?: number,
    isActiveScreen: boolean,
    feedbackContent?: string,
}

export interface NotificationDialogModel {
    showNotifications: NotificationDialogCard[],
    allNotifications: NotificationDialogCard[],
    allFormState: {[key:string]:string}
}

export const EMPTY_NOTIFICATION_DIALOG_MODEL: NotificationDialogModel = {
    showNotifications: [],
    allNotifications: [],
    allFormState: {}
}

export const EMPTY_NOTIFICATION_DIALOG : NotificationDialogCard = {
    isActiveScreen: false
}