import { AlessiaRepo } from "./AlessiaRepo";

export const AlessiaRepoImpl = (token?: string): AlessiaRepo => {
    const backendContextPath = '/alessia-mgmt-portal-backend/api';

    const setDefaultBu = async(bu: string, email: string) => {
        try {
            const response = await fetch(`${backendContextPath}/user/updateUserDefaultBu`, {
                method: "POST",
                body: JSON.stringify({
                    defaultBu: bu,
                    username: email
                }),
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    'X-CSRF-Token': ''
                },
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            return response;
        } catch (error) {
            throw error;
        }
    };

    return {
        setDefaultBu: setDefaultBu,
    };
};
