import { ArpitaTemplateEntity } from "domain/entity/ArpitaTemplateEntity/ArpitaTemplateEntity";
import { NavigationEntity } from "domain/entity/Navigation";
import { ArpitaRepoImpl } from "domain/repository/Arpita/ArpitaRepoImpl";
import { MenuRepoImpl } from "domain/repository/Menu/MenuRepoImpl";
import { filterNavByPermissions } from "domain/utils/recursionPropertyInsertion";
import { DialogUtils } from "helpers/DialogUtils";
import _ from "lodash";
import { useANAInfo } from "presentation/hook/ANAInfo/useANAInfo";
import { useEnvironmentVariableConfig } from "presentation/hook/useEnvironmentVariableConfig";
import { useInitApm } from "presentation/hook/useInitApm";
import { useLoginTracking } from "presentation/hook/useLoginTracking";
import { WorkspaceProvider, useWorkspaceTracked } from "presentation/store/NavigationMenu/NavigationMenu";
import { NotificationDialogProvider } from "presentation/store/NotificationDialog/NotificationDialogProvider";
import { VersionInformation } from "presentation/view/components/workspace/VersionInformation/VersionInformation";
import MainView from "presentation/view/section/workspace/MainView/MainView";
import { ModalMainView } from "presentation/view/section/workspace/ModalMainView/ModalMainView";
import { NavigationMenu } from "presentation/view/section/workspace/NavigationMenu/NavigationMenu";
import SupportingModule from "presentation/view/section/workspace/SupportingModule/SupportingModule";
import { Suspense, useEffect, useState } from "react"; //useState
import { Loader } from "veronica-ui-component/dist/component/core";
import { useFrontendConsoleTracking } from "../hook/useFrontendConsoleTracking";
import { StoredWorkspaceModel } from "../model/workspaceModel";
import { SwitchBuIframe } from "../view/section/workspace/SwitchBuIframe/SwitchBuIframe";

function getTemplateData(hits: any, userName: string = '') {
    const temp: ArpitaTemplateEntity[] = [];

    hits.forEach((hit: any) => {
        if (hit.sourceAsMap.isMaster || hit.sourceAsMap.userName === userName) {
            temp.push({
                id: hit.id,
                name: hit.sourceAsMap.templateName ? hit.sourceAsMap.templateName : "",
                pinned: hit.sourceAsMap.pinned ? hit.sourceAsMap.pinned : false,
                isMaster: hit.sourceAsMap.isMaster,
                isOpen: false,
                dataSet: hit.sourceAsMap.dataSource?.index,
                templateType: hit.sourceAsMap.templateType || 'Template',
            })
        }
    })

    return temp;
}

const MainContainer: React.FC = () => {
    const [workspaceState, setWorkspaceState] = useWorkspaceTracked();
    const { showChargeSearch, showDocumentSearch, showDocumentItemSearch, showProposalSearch, showArpitaTemplate, entrypoint } = workspaceState;
    const { token, entryPoint: anaEntryPoint, userName, currentOperatingCompany, allNbisivPermission } = useANAInfo();
    const [isGetArpitaMenu, setIsGetArpitaMenu] = useState<boolean>(true);

    useLoginTracking(workspaceState.identifier);
    useFrontendConsoleTracking(workspaceState.identifier);
    useEnvironmentVariableConfig();
    useInitApm();

    useEffect(() => {
        if (!token) return;
        MenuRepoImpl().getMenuByKey('nbisiv-main-menu').then((menu) => {
            //filter the menu by permission
            let filteredNavigation: NavigationEntity[] = [];
            if (menu) {
                filteredNavigation = filterNavByPermissions(menu.navigation ?? [], allNbisivPermission ?? {}, currentOperatingCompany);
            }

            setWorkspaceState && setWorkspaceState((prevState: any) => ({
                ...prevState,
                mainMenu: {
                    ...prevState.mainMenu,
                    ...menu,
                    navigation: filteredNavigation,
                },
                berthMenu: []
            }));
        });
    }, [token, userName, setWorkspaceState, allNbisivPermission, currentOperatingCompany]);

    //no necessary at the moment
    useEffect(() => {
        if (!token) return;
        if (isGetArpitaMenu) {
            ArpitaRepoImpl().getAllId().then((result) => {
                const templates = result.hits && _.orderBy(getTemplateData(result.hits), [item => item.id.toLowerCase()], ['asc']);
                setWorkspaceState && setWorkspaceState((prevState: any) => ({ ...prevState, arpitaTemplates: templates }));
                setIsGetArpitaMenu((prevState => !prevState));
            });
        }
    }, [token, isGetArpitaMenu, setWorkspaceState]);

    useEffect(() => {
        const storedWorkspaceState: StoredWorkspaceModel = {
            defaultPageInformation: workspaceState.defaultPageInformation,
            entrypoint: anaEntryPoint ?? entrypoint, //workspaceState.entrypoint,
            expanded: true,//entryPoint?true:false,//workspaceState.expanded,
            isMainMenu: workspaceState.isMainMenu,
            isSupportingModules: workspaceState.isSupportingModules,
            mainNavTitle: workspaceState.mainNavTitle,
            mfeFullscreen: anaEntryPoint ?? entrypoint ? true : false,//workspaceState.mfeFullscreen,
            mfeGraphic: workspaceState.mfeGraphic,
            mfePageInformation: workspaceState.mfePageInformation,
            mfeSupTitle: workspaceState.mfeSupTitle,
            mfeTitle: workspaceState.mfeTitle,
            miniToolbarArr: workspaceState.miniToolbarArr,
            path: workspaceState.path,
            showMainView: anaEntryPoint ?? entrypoint ? true : false,//workspaceState.showMainView,
            showNavigation: anaEntryPoint ?? entrypoint ? true : false,//workspaceState.showNavigation,
        }
        sessionStorage.setItem('workspace-state', JSON.stringify(storedWorkspaceState));
        if (workspaceState.showDefaultEntryPoint) {
            setWorkspaceState(prevState => ({
                ...prevState,
                ...storedWorkspaceState,
                showDefaultEntryPoint: false
            }));
        }
    })

    useEffect(() => {
        const mousedownCb = (e: any) => DialogUtils.removeActiveDialog(e);
        document.querySelector(`body`)?.addEventListener('mousedown', mousedownCb);
        return () => document.querySelector(`body`)?.removeEventListener('mousedown', mousedownCb);
    }, []);

    useEffect(() => {
        if (showChargeSearch || showDocumentSearch || showDocumentItemSearch || showProposalSearch || showArpitaTemplate) {
            setTimeout(() => DialogUtils.addRemoveModalClassOnBody(), 0);
        }
    }, [setWorkspaceState, showChargeSearch, showDocumentSearch, showDocumentItemSearch, showProposalSearch, showArpitaTemplate]);

    return (
        <Suspense fallback={<Loader Indicator="Stripe" size="Large" />}>
            <div className="work-space">
                <main>
                    <div className="wrapper">
                        <NavigationMenu />
                        <MainView />
                        <SupportingModule />
                        <ModalMainView updateArpitaMenu={() => setIsGetArpitaMenu((prevState => !prevState))} />
                        <SwitchBuIframe />
                        <VersionInformation />
                    </div>
                </main>
            </div>
        </Suspense>);
}

export const Layout = () => <WorkspaceProvider><NotificationDialogProvider><MainContainer /></NotificationDialogProvider></WorkspaceProvider>;

export default Layout;