import axios from "axios";
import { EMPTY_ENVIRONMENT_VARIABLE_ENTITY, EnvironmentVariableEntity } from "domain/entity/EnvironmentVariables";
import { EnvironmentVariableRepo } from "./EnvironmentVariableRepo";

export const EnvironmentVariableRepoImpl = (): EnvironmentVariableRepo => {
    const getEnvironmentVariable = async (path:string=""): Promise<EnvironmentVariableEntity> => {
        return fetch(`${path}/asset-manifest.json`, { cache: 'no-cache' })
        .then((res) => res.json())
        .then((manifest) => {
            const jsonFileName = manifest.jsonFiles['environmentVariables.json'];
            const result = axios.get(jsonFileName)
                .then(res => {
                    return res.data;
                })
                .catch(() => {
                    return EMPTY_ENVIRONMENT_VARIABLE_ENTITY
                });
            return result;
        });
    };

    return {
        getEnvironmentVariable: getEnvironmentVariable,
    };
};
